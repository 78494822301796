import {CST} from "../CST";
import game from "../main.js";
import defaultFont, {alternateFont, hanalei} from "../DeafultFont.js";
import Button from "../Button-Modo2";

export class InstructionsGame2_2 extends Phaser.Scene{
    constructor() {
        super({
            key: CST.SCENES.INSTRUCTIONSGAME2_2
        });
    }
    init(){
    }
    preload(){
        Button.load(this);
    }
    create(){

        window.addEventListener('resize', resize);
        resize();

        //fundo game
        this.add.rectangle(0, 0, game.config.width, game.config.height, 0x070909).setOrigin(0).setDepth(0);

        //chão
        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width, 150, 0x206623).setDepth(0).setOrigin(0)
        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width, 150, 0x206623).setDepth(0).setOrigin(0)

        //logo
        this.add.image((this.game.renderer.width/2), this.game.renderer.height*0.9, "logo").setScale(1).setOrigin(0.5,0.5);

         //titulo
         this.add.text(40, 20,"Dino Divisores",{...alternateFont, color: '#e0e0e0', fontSize: 90}).setDepth(5);
         this.add.text(40, 20,"Dino Divisores",{...hanalei, color: '#415575', fontSize: 90}).setDepth(5);

        //texto
        
        // BRANCO
        this.add.text(40, 160,"Os números já marcados de vermelho na etapa 2, podem ser remarcados\nPor exemplo, ao fim da etapa 2 do nosso exemplo, restaram os números\n02          04    05    06    07    08      \n\nAgora o jogador vermelho escolhe 06\nEntão o jogador verde marca 02, 03 (que havia sido marcado de vermelho pelo jogador vermelho) e 06.\n            04    05          07    08      \n\nO Jogo continua, voltando à etapa 1, até não existirem mais números para serem marcados no tabuleiro.\nCuidado! Não é permitido desmarcar um número marcado na rodada corrente.\nGanha o jogador que tiver mais números marcados com a sua cor",{...defaultFont, color: '#e0e0e0', align: "left", fontSize: 20, wordWrap: { width: (this.game.renderer.width - 80) }}).setDepth(5).setOrigin(0, 0).setLineSpacing(10);
        
        //VERDE
        this.add.text(40, 160,"Os números já marcados de vermelho na etapa 2, podem ser remarcados\nPor exemplo, ao fim da etapa 2 do nosso exemplo, restaram os números\n02          04    05    06    07    08      \n\nAgora o jogador vermelho escolhe 06\nEntão o jogador verde marca 02, 03 (que havia sido marcado de vermelho pelo jogador vermelho) e 06.\n02    03    04    05    06    07    08      \n\nO Jogo continua, voltando à etapa 1, até não existirem mais números para serem marcados no tabuleiro.\nCuidado! Não é permitido desmarcar um número marcado na rodada corrente.\nGanha o jogador que tiver mais números marcados com a sua cor",{...defaultFont, color: '#4D8C4D', align: "left", fontSize: 20, wordWrap: { width: (this.game.renderer.width - 80) }}).setDepth(4).setOrigin(0, 0).setLineSpacing(10);

        //VERMELHO
        this.add.text(40, 160,"Os números já marcados de vermelho na etapa 2, podem ser remarcados\nPor exemplo, ao fim da etapa 2 do nosso exemplo, restaram os números\n02    03    04    05    06    07    08    09\n\nAgora o jogador vermelho escolhe 06\nEntão o jogador verde marca 02, 03 (que havia sido marcado de vermelho pelo jogador vermelho) e 06.\n02    03    04    05    06    07    08    09\n\nO Jogo continua, voltando à etapa 1, até não existirem mais números para serem marcados no tabuleiro.\nCuidado! Não é permitido desmarcar um número marcado na rodada corrente.\nGanha o jogador que tiver mais números marcados com a sua cor",{...defaultFont, color: '#942637', align: "left", fontSize: 20, wordWrap: { width: (this.game.renderer.width - 80) }}).setDepth(3).setOrigin(0, 0).setLineSpacing(10);
        
        //buttons modos
        let menu = new Button(this, {label: "Voltar", position: new Phaser.Math.Vector2(this.game.renderer.width/2 - 400, this.game.renderer.height - 185), fontSize: "20px", color:"#ffffff", type:"recButton", size: 220, onclick: () => {this.scene.start(CST.SCENES.MENU, "Menu")}})

        let playGame2 = new Button(this, {label: "Começar", position: new Phaser.Math.Vector2(this.game.renderer.width/2 + 180, this.game.renderer.height - 185), fontSize: "20px", color:"#ffffff", type:"recButton", size: 220, onclick: () => {this.scene.start(CST.SCENES.MODO2, "PLAY GAME 2")}})

        function resize(){
            let canvas = game.canvas, width = document.getElementById("meu-jogo").offsetWidth, height = window.innerHeight;
            let wratio = width / height, ratio = canvas.width / canvas.height;

            if (wratio < ratio) {
                canvas.style.width = width + 'px';
                canvas.style.height = (width / ratio) + 'px';
            } else {
                canvas.style.width = (height * ratio) + 'px';
                canvas.style.height = height + 'px';
            }
        }
    }
}