import {CST} from "../CST.js";
import WebFontFile from "../WebFontFile.js";
import fundo from "../assets/fundo.png";
import logo from "../assets/layoutMenu/logoVertical2.png";
import tabuleiro from "../assets/tabuleiro.png";
import divisor from "../assets/layoutMenu/divisor.png";
import dinoVerde from "../assets/dino/gifs/DinoSprites_vita.gif";
import dinoVermelho from "../assets/dino/gifs/DinoSprites_mort.gif";
import shadow from "../assets/dino/misc/shadow_2.png";
import dinoVerdeWinner from "../assets/dino/videos/dinoVerdeWinner.mp4";
import dinoVermelhoWinner from "../assets/dino/videos/dinoVermelhoWinner.mp4";

export class LoadScene extends Phaser.Scene{
    constructor() {
        super({
            key: CST.SCENES.LOAD
        });
    }
    init(){
    }
    preload(){
        window.onclick = () => {
            var musica = document.getElementById('musica');
            musica.volume = 0.1;
            musica.play();
        }               
    
        this.load.addFile(new WebFontFile(this.load, ['Press Start 2P','Hanalei Fill', 'Hanalei']));

        this.load.image("background", fundo);

        this.load.image("logo", logo);

        this.load.image("tabletop",tabuleiro);

        this.load.image("divisor", divisor);

        this.load.image("dinoVerde", dinoVerde);

        this.load.image("dinoVermelho", dinoVermelho);

        this.load.image("shadow", shadow);

        this.load.video("dinoVerdeWinner", dinoVerdeWinner, "loadeddata", false, false)
        this.load.video("dinoVermelhoWinner", dinoVermelhoWinner, "loadeddata", false, false)

        this.load.on("load",(file)=>{
            console.log(file.src)
        })

    }
    create(){
        this.scene.start(CST.SCENES.MENU,"Hello from loadScene");
    }
}