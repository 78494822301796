import {CST} from "../CST";
import Button from "../Button-Modo2";
import TurnManager from "../TurnManager-Modo2";
import game from "../main.js"
import {alternateFont} from "../DeafultFont.js";

export class Modo2Scene extends Phaser.Scene {
    constructor() {
        super({
            key: CST.SCENES.MODO2
        });
    }

    init(data) {
        console.log(data)
        console.log("got the message")
    }

    preload() {
        Button.load(this);
    }


    create() {

        this.modalAvisoMarcacao(this);

        let turnManager = new TurnManager();

        let scope = this;
        turnManager.load(this);

        window.addEventListener('resize', resize);
        resize();

        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width,150, 0x206623).setDepth(0).setOrigin(0)
        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width,150, 0x206623).setDepth(0).setOrigin(0)
        let greenPoints = this.add.text(300,this.game.renderer.height-250, "0", {...alternateFont,color:"green", fontSize: 70}).setDepth(0).setOrigin(0)
        let redPoints = this.add.text(850,this.game.renderer.height-250, "0", {...alternateFont,color:"red", fontSize: 70}).setDepth(0).setOrigin(0)
        this.add.image(200, this.game.renderer.height -180, "dinoVerde").setScale(6);
        this.add.image(200, this.game.renderer.height -180, "shadow").setScale(6);
        this.add.image(1000, this.game.renderer.height -180, "dinoVermelho").setScale(6).toggleFlipX();
        this.add.image(1000, this.game.renderer.height -180, "shadow").setScale(6);


        let buttonStartPosition = new Phaser.Math.Vector2(160,200);
        const buttons = [];


        for (let i = 0; i < 8*3; i++) {
            let row = Math.floor(i/8), col = i%8, buttonSize = 90, gap = 20, playerColor = "#ffffff";
            let button = new Button(this, {
                onclick: function(){this.updateButton(playerColor,turnManager,scope,greenPoints,redPoints)},
                color: "#ffffff",
                position: buttonStartPosition.clone().add({
                    x: col * (buttonSize + gap),
                    y: row * (buttonSize + gap)

                }),
                size: buttonSize,
                label: i + 2
            });
            buttons.push(button)
        }

        let menuButton = new Button(this,{label:"Menu", position:new Phaser.Math.Vector2(20,10),
            fontSize: "12px",color:"#ffffff",type:"recButton",size: 100,onclick: function(){
                this.scene.start(CST.SCENES.MENU)
            }.bind(this)
        });

        let restartButton = new Button(this,{label:"Restart", position:new Phaser.Math.Vector2(20,60),
            fontSize: "12px",color:"#ffffff",type:"recButton",size: 100,onclick: function(){
                this.scene.start(CST.SCENES.INSTRUCTIONS,"Restartando...");
            }.bind(this)
        });




        let confirmButton = new Button(this,{label:"Confirmar", position:new Phaser.Math.Vector2(500,550),
            fontSize: "20px",color:"#ffffff",type:"recButton",size: 200, onclick: function (){
            turnManager.updateTurns(scope, greenPoints, redPoints);
            }
        });

        function resize(){
            let canvas = game.canvas, width = document.getElementById("meu-jogo").offsetWidth, height = window.innerHeight;
            let wratio = width / height, ratio = canvas.width / canvas.height;

            if (wratio < ratio) {
                canvas.style.width = width + 'px';
                canvas.style.height = (width / ratio) + 'px';
            } else {
                canvas.style.width = (height * ratio) + 'px';
                canvas.style.height = height + 'px';
            }
        }
    }

    /**
        * @param {Phaser.Scene} scene
        */
    modalAvisoMarcacao = function (scene){
        let modal = document.getElementById('modalRespostaErrada');
        let retanguloFundo = scene.add.rectangle(scene.game.renderer.width/2, scene.game.renderer.height/2, scene.game.renderer.width, scene.game.renderer.height,0x000000).setDepth(1).setInteractive().setAlpha(0.5).setVisible(true);

        modal.innerHTML = `<p>Cuidado! Não é permitido desmarcar um número marcado na rodada corrente.</p><button class="close">OK</button>`;                


        let span = document.getElementsByClassName("close")[0];
        modal.style.display = 'block';

        function closeModal() {
        modal.style.display = "none";
        retanguloFundo.setVisible(false);
        }

        span.onclick = function() {
            closeModal();
        }
        }


}