import defaultFont from "./DeafultFont.js";
import {CST} from "./CST.js";
import Button from "./Button.js";
import { PlayScene } from "./scenes/PlayScene.js";
/*
    Tarefas:
    - Criar 2 novas variáveis nesta classe. Uma para armazenar o número que será selecionado para encontrar os divisores, e uma para armazenar os numeros que serão marcados como divisores do numero selecionado.
    - Criar funções de get e de set. Inserir e pegar os valores das variáveis criadas acima. 
    - Criar uma função que verifique se o numero selecionado é divisor do numero marcado
*/
export default class TurnManager extends Phaser.Scene {
    /**
     * @param {Phaser.Scene} scene
     */
    updateTurns = function (gameScene,labelGreenPoints,labelRedPoints) {
        //debugger;
        // Criar um if que entre apenas se for "selecionar". 
        if(this.currentState === "selecionar") {
            if(this.mainNumber === null || this.mainNumber === 0) {
                this.modalSelecionar(gameScene);
                return;
            }
        
        }
        if(this.currentPlayer === "green"){
            this.greenTurnText.setVisible(false);
            this.greenSelectText.setVisible(false);
            this.redTurnText.setVisible(true);
            this.redSelectText.setVisible(true);

            if (this.currentState === "marcar"){
                this.setMainNumber(0);
                this.redSelectText.setText("Selecione um número e aperte confirmar");
                this.currentState = "selecionar";
                this.currentPlayer = "red";

            }else{
                this.redSelectText.setText("Marque os números que são divisores\ndo número escolhido");
                this.currentState = "marcar";

            }
        }else if (this.currentPlayer === "red"){
            this.greenTurnText.setVisible(true);
            this.greenSelectText.setVisible(true);
            this.redTurnText.setVisible(false);
            this.redSelectText.setVisible(false);

            if (this.currentState === "marcar"){
                this.setMainNumber(0);
                this.greenSelectText.setText("Selecione um número e aperte confirmar");
                this.currentState = "selecionar";
                this.currentPlayer = "green";
            }else{
                this.greenSelectText.setText("Marque os números que são divisores\ndo número escolhido");
                this.currentState = "marcar";
            }
        }
        //Percorre toda a array de números selecionados
        for (let button of this.roundNumberSelected) {
            if(button.label.style.color === "#4D8C4D"){
                this.greenPoints++;
                button.painted = true;
            }
            if(button.label.style.color === "#942637"){
                this.redPoints++;
                button.painted = true;
            }
        }
        labelGreenPoints.setText(this.greenPoints);
        labelRedPoints.setText(this.redPoints);

        if (this.greenPoints + this.redPoints === 24){
            if (this.greenPoints > this.redPoints){
                gameScene.scene.start(CST.SCENES.WIN, {winner: "Verde", color: "green", redPoints: this.redPoints, greenPoints: this.greenPoints});
            }else if (this.greenPoints < this.redPoints){
                gameScene.scene.start(CST.SCENES.WIN, {winner: "Vermelho", color: "red", redPoints: this.redPoints, greenPoints: this.greenPoints});
            }else{
                gameScene.scene.start(CST.SCENES.WIN, {winner: "Empate", color: "#e0e0e0", redPoints: this.redPoints, greenPoints: this.greenPoints});
            }
        }

        this.roundNumberSelected.length = 0;
    }
    /**
     * @param {Phaser.Scene} scene
     */
    selectNumber = function (scene,number){
        if(this.currentNumberText === null) {
            this.currentNumberText = scene.add.text(1050, 150, number, {...defaultFont, fontSize: "50px"})
        }else{
            this.currentNumberText.setText(number);
        }
    }

    load = function (scene){
        this.greenTurnText= scene.add.text(220,60,"Vez do Verde",{...defaultFont, fontSize: "50px", fill: "#206623"});
        this.redTurnText = scene.add.text(220,60,"Vez do Vermelho",{...defaultFont, fontSize: "50px", fill: "#942637FF"});
        this.redSelectText = scene.add.text(220,130,"Selecione um número e aperte confirmar",{...defaultFont, fontSize: "20px", fill: "#942637FF"})
        this.greenSelectText = scene.add.text(220,130,"Selecione um número e aperte confirmar",{...defaultFont, fontSize: "20px", fill: "#206623"});
        this.greenTurnText.setVisible(true);
        this.greenSelectText.setVisible(true);
        this.redTurnText.setVisible(false);
        this.redSelectText.setVisible(false);
        this.currentPlayer = "green";
        this.currentState = "selecionar";
    }
    currentPlayer = "";
    currentState = "";

    lastButton = null;

    mainNumber = null; //Numero que foi marcado para encontrar os divisores
    answer = null; //Numero que foi selecionado como divisor do mainNumber
    
    setMainNumber = function (number) {
        this.mainNumber = parseInt(number);
    }
    setAnswer = function (answer) {
        this.answer = parseInt(answer);
    }

    getLastButton = function (){
        return this.lastButton
    }
    setLastButton = function (button){
        this.lastButton = button;
    }

    addroundNumberSelected = function (button) {
        if(this.roundNumberSelected.length > 0) {
            for (let i = 0; i < this.roundNumberSelected.length; i++) {
                if(this.roundNumberSelected[i].label.text === button.label.text) {
                    return;
                }
            }
        }
        this.roundNumberSelected.push(button);
    }

    greenTurnText = ""
    redTurnText = ""

    currentNumberText = null;

    roundNumberSelected = [];

    redSelectText = ""
    greenSelectText = ""

    greenPoints = 0;
    redPoints = 0;

    checkAnswer = function (){
        if(this.mainNumber % this.answer != 0) {
            return false;
        }

        return true;
    }

    
    //mostra o modal de número selecionado errado
     /**
     * @param {Phaser.Scene} scene
     */
    modalRespostaErrada = function (scene){
        let modal = document.getElementById('modalRespostaErrada');
        let retanguloFundo = scene.add.rectangle(scene.game.renderer.width/2, scene.game.renderer.height/2, scene.game.renderer.width, scene.game.renderer.height,0x000000).setDepth(1).setInteractive().setAlpha(0.5).setVisible(true);

        modal.innerHTML = `<p>O número ${this.answer} não é divisor do número ${this.mainNumber}!<br>Tente       novamente.</p>
                            <button class="close">OK</button>`;                


        let span = document.getElementsByClassName("close")[0];
            modal.style.display = 'block';

        function closeModal() {
            modal.style.display = "none";
            retanguloFundo.setVisible(false);
        }

        span.onclick = function() {
            closeModal();
        }
    }

    /**
    * @param {Phaser.Scene} scene
    */
    modalSelecionar = function (scene){
        let modal = document.getElementById('modalRespostaErrada');
        let retanguloFundo = scene.add.rectangle(scene.game.renderer.width/2, scene.game.renderer.height/2, scene.game.renderer.width, scene.game.renderer.height,0x000000).setDepth(1).setInteractive().setAlpha(0.5).setVisible(true);

        modal.innerHTML = `<p>Selecione um número antes de confirmar a seleção!</p><button class="close">OK</button>`;                


        let span = document.getElementsByClassName("close")[0];
            modal.style.display = 'block';

        function closeModal() {
            modal.style.display = "none";
            retanguloFundo.setVisible(false);
        }

        span.onclick = function() {
            closeModal();
        }
    }
}

        
    
        
    
        
    

